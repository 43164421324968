import {
  collection,
  onSnapshot,
  query,
  where,
  getCountFromServer,
  Unsubscribe,
} from "firebase/firestore";

export const useUserDocuments = (userId: string) =>
  definePiniaStore(`/users/${userId}/documents`, () => {
    const ungradedDocuments = ref<SubmittedDocuments>([]);
    const subscription = ref<Unsubscribe | undefined>();

    const isInitialized = ref(false);

    const initialize = () => {
      if (isInitialized.value) return;

      const db = useFirestore();
      const documentsRef = collection(db, `/documents`);
      const documentsQuery = query(
        documentsRef,
        where("userId", "==", userId),
        where("state", "not-in", [
          DocumentState.studentReview,
          DocumentState.graded,
          DocumentState.archived,
          DocumentState.ignored,
        ])
      );

      if (subscription.value) {
        subscription.value();
      }

      subscription.value = onSnapshot(documentsQuery, (snapshot) => {
        ungradedDocuments.value = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          } as SubmittedDocument;
        });
      });

      isInitialized.value = true;
    };

    const allUngradedDocuments = computed(() => {
      return ungradedDocuments.value;
    });

    const classroomUngradedDocuments = computed(() => {
      return (classroomId: string) => {
        return ungradedDocuments.value.filter(
          (doc) => doc.classroomId === classroomId
        );
      };
    });

    const assignmentUngradedDocuments = computed(() => {
      return (assignmentId: string) => {
        return ungradedDocuments.value.filter(
          (doc) => doc.assignmentId === assignmentId
        );
      };
    });

    const appUserDataStore = useAppUserData();
    const { numGradedDocuments } = storeToRefs(appUserDataStore);

    return {
      initialize,
      allUngradedDocuments,
      classroomUngradedDocuments,
      assignmentUngradedDocuments,
      numGradedDocuments,
    };
  });
